/** @define LocatorFilters */

.LocatorFilters
{
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: white;

  &-content
  {
    display: flex;
    flex-direction: column;

    label
    {
      margin-bottom: 20px;
    }
  }

  &-titleRow
  {
    @include font-sizes(16px, 20px);
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    font-weight: $font-weight-bold;
    margin-bottom: 40px;
    border-bottom: $divider-dark;
    padding: 8px;
  }

  &-content
  {
    @include font-sizes(16px, 20px);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: hidden;
    padding: 1px;
    margin-left: 79px;
    margin-right: 79px;

    label
    {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      margin-bottom: 20px;
    }

    input,
    [type=checkbox]
    {
      margin-right: 8px;
      cursor: pointer;
      height: 20px;
      width: 20px;
      accent-color: $gray-2;
      appearance: revert !important;
      -webkit-appearance: revert !important;
      -moz-appearance: revert !important;
    }
  }

  &-close
  {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    position: absolute;
    right: 18px;

    &:focus
    {
      outline: 1px dashed black;
    }

    &::-moz-focus-inner
    {
      outline: 0;
      border: 0;
    }
  }

  &-buttons
  {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding-top: 8px;
    align-items: center;
  }

  /* postcss-bem-linter: ignore */
  .js-filters-apply
  {
    @include font-sizes(20px, 28px);
    color: $white;
    background-color: $locator-button-red;
    order: 1;

    border-radius: 8px;
    box-shadow: $filter-shadow;
    margin-bottom: 32px;

    @include bpgte(md)
    {
      width: 240px;
    }

    @include bplte(sm)
    {
      width: 160px;
    }
  }

  &-button
  {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;

    &[disabled]
    {
      background-color: $gray-3;
      cursor: default;
    }
  }

  /* postcss-bem-linter: ignore */
  .js-filters-clear
  {
    @include font-sizes(16px, 20px);
    text-decoration: underline;
    order: 2;
  }
}
