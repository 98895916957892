/** @define Text */

@mixin Text--footer
{
  @include font-sizes(14px, 16px);

  color: $footer-black;
}

@mixin Text--footerSmall
{
  @include Text--footer;
  @include font-sizes(12px, 14px);
}

@mixin Text--bold
{
  font-weight: $font-weight-bold;
}

.Text
{
  font-size: 14px;

  @include bpgte(sm)
  {
    font-size: 16px;
  }

  &--small
  {
    font-size: 12px;

    @include bpgte(sm)
    {
      font-size: 14px;
    }
  }

  &--large
  {
    font-size: 16px;

    @include bpgte(sm)
    {
      font-size: 18px;
    }
  }

  &--bold
  {
    font-weight: $font-weight-bold;
  }

  &--light
  {
    font-weight: $font-weight-light;
  }

  &--semibold
  {
    font-weight: $font-weight-semibold;
  }

  &--headerSmall
  {
    @include font-sizes(12px, 16px);
  }

  &--headerLarge
  {
    @include font-sizes(16px, 24px);
    font-weight: $font-weight-semibold;
  }
}
