/** @define Main */

.Main
{
  @include bpgte(sm)
  {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;

    &-breadCrumbs
    {
      order: -99;
    }
  }
}
