// Add any font families here

@font-face
{
  font-family: 'Gotham';
  src: url('assets/fonts/Gotham-Black.otf') format('opentype');
  font-weight: $font-weight-xbold;
  font-style: normal;
  font-display: swap;
}

@font-face
{
  font-family: 'Gotham';
  src: url('assets/fonts/Gotham-BlackIta.otf') format('opentype');
  font-weight: $font-weight-xbold;
  font-style: italic;
  font-display: swap;
}

@font-face
{
  font-family: 'Gotham';
  src: url('assets/fonts/Gotham-Bold.otf') format('opentype');
  font-weight: $font-weight-bold;
  font-style: normal;
  font-display: swap;
}

@font-face
{
  font-family: 'Gotham';
  src: url('assets/fonts/Gotham-BoldIta.otf') format('opentype');
  font-weight: $font-weight-bold;
  font-style: italic;
  font-display: swap;
}

@font-face
{
  font-family: 'Gotham';
  src: url('assets/fonts/Gotham-Medium.otf') format('opentype');
  font-weight: $font-weight-semibold;
  font-style: normal;
  font-display: swap;
}

@font-face
{
  font-family: 'Gotham';
  src: url('assets/fonts/Gotham-MediumIta.otf') format('opentype');
  font-weight: $font-weight-semibold;
  font-style: italic;
  font-display: swap;
}

@font-face
{
  font-family: 'Gotham';
  src: url('assets/fonts/Gotham-Book.otf') format('opentype');
  font-weight: $font-weight-normal;
  font-style: normal;
  font-display: swap;
}

@font-face
{
  font-family: 'Gotham';
  src: url('assets/fonts/Gotham-BookIta.otf') format('opentype');
  font-weight: $font-weight-normal;
  font-style: italic;
  font-display: swap;
}

@font-face
{
  font-family: 'Gotham';
  src: url('assets/fonts/Gotham-Light.otf') format('opentype');
  font-weight: $font-weight-light;
  font-style: normal;
  font-display: swap;
}

@font-face
{
  font-family: 'Gotham';
  src: url('assets/fonts/Gotham-LightIta.otf') format('opentype');
  font-weight: $font-weight-light;
  font-style: italic;
  font-display: swap;
}

@font-face
{
  font-family: 'Gotham';
  src: url('assets/fonts/Gotham-Thin.otf') format('opentype');
  font-weight: $font-weight-xlight;
  font-style: normal;
  font-display: swap;
}

@font-face
{
  font-family: 'Gotham';
  src: url('assets/fonts/Gotham-ThinIta.otf') format('opentype');
  font-weight: $font-weight-xlight;
  font-style: italic;
  font-display: swap;
}

@font-face
{
  font-family: 'GothamBook';
  src: url('assets/fonts/Gotham-Book.otf') format('opentype');
  font-weight: $font-weight-normal;
  font-style: normal;
  font-display: swap;
}

@font-face
{
  font-family: 'GothamBook';
  src: url('assets/fonts/Gotham-Book.otf') format('opentype');
  font-weight: $font-weight-light;
  font-style: normal;
  font-display: swap;
}

@font-face
{
  font-family: 'Open Sans';
  src: url('opensans-regular-webfont.eot');
  src:
    url('opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('opensans-regular-webfont.woff') format('woff'),
    url('opensans-regular-webfont.ttf')  format('truetype'),
    url('opensans-regular-webfont.svg#open_sansregular') format('svg');
  font-weight: $font-weight-normal;
  font-display: swap;
}

@font-face
{
  font-family: 'Open Sans';
  src: url('opensans-bold-webfont.eot');
  src:
    url('opensans-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url('opensans-bold-webfont.woff') format('woff'),
    url('opensans-bold-webfont.ttf')  format('truetype'),
    url('opensans-bold-webfont.svg#open_sansbold') format('svg');
  font-weight: $font-weight-bold;
  font-display: swap;
}

@font-face
{
  font-family: 'Open Sans';
  src: url('opensans-italic-webfont.eot');
  src:
    url('opensans-italic-webfont.eot?#iefix') format('embedded-opentype'),
    url('opensans-italic-webfont.woff') format('woff'),
    url('opensans-italic-webfont.ttf')  format('truetype'),
    url('opensans-italic-webfont.svg#open_sansitalic') format('svg');
  font-weight: $font-weight-normal;
  font-style: italic;
  font-display: swap;
}
