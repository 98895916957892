//temp override for footer client css on button
header
{
  a:visited
  {
    color: inherit;
  }
}

.HomeServices-body
{
  -webkit-font-smoothing: antialiased;

  .container
  {
    @include l-container;
  }

  .site-links-outer-label
  {
    @include bplte(xs)
    {
      padding: 20px 0 0;
    }
  }

  .breadcrumbs-desktop
  {
    right: 0;
  }

  .Header .row
  {
    margin: 0;
  }

  .header-navbar-list
  {
    padding-left: 16px;
    padding-right: 16px;
  }

  h4
  {
    line-height: 1.33;
  }
}

abbr[title]
{
  text-decoration: none;
  border: none;
}

.footer-container
{
  margin-top: 16px;
}

button
{
  border: none;
  padding: 0;
  margin: 0;
  background: none;
}

h1
{
  @include bplte(sm)
  {
    font-weight: 600;
  }
}

.container
{
  margin: auto;

  @include bpgte(sm)
  {
    width: 768px;
  }

  @include bpgte(md)
  {
    width: $container-desktop-base;
  }

  @include bpgte(lg)
  {
    width: $container-large-desktop-base;
  }

  @media only screen and (min-width: 1300px)
  {
    width: $container-xl-desktop-base;
  }
}
// Remove Map/Satellite View Controls on Google Maps
.gm-style-mtc
{
  display: none !important;
}

.show-map-text
{
  margin-bottom: 0;
}

.breadcrumbs-desktop
{
  position: relative;
  right: 7px;

  @include bplte(sm)
  {
    display: none;
  }
}

.breadcrumbs-mobile
{
  font-size: 16px;
  font-weight: $font-weight-semibold;

  @include bpgte(md)
  {
    display: none;
  }
}

.c-bread-crumbs
{
  margin: 20px 0;

  @include bpgte(md)
  {
    padding-left: 16px;
  }

  &-list
  {
    @include bplte(xs)
    {
      margin:
      {
        left: 15px;
        right: 15px;
      }
    }
  }

  &-item
  {
    @include font-sizes(14px, 20px);
    font-weight: $font-weight-normal;
    color: $footer-black;

    a
    {
      color: $footer-black;
      text-decoration: underline;

      &:hover
      {
        text-decoration: none;
      }
    }

    &::before
    {
      content: " ";
      background-image: url('assets/images/breadcrumb-arrow.svg');
      height: 15px;
      width: 9px;
      display: inline-block;
      background-repeat: no-repeat;
      margin-left: 9px;
      margin-right: 9px;
    }

    &:first-child
    {
      &::before
      {
        display: none;
      }
    }

    &:last-child span
    {
      color: $gray-11;
    }
  }
}
// TODO: Nest these:
.state-search-container
{
  padding: 15px;
}

.state-results-container
{
  position: relative;
  margin-top: 10px;

  @include bpgte(md)
  {
    border: $divider-light;
  }
}

.c-location-hours-details-row
{
  @include bplte(sm)
  {
    font-size: 12px;
  }
}

/* postcss-bem-linter: ignore */
.c-location-hours-collapse
{
  .c-hours-toggle
  {
    @include bpgte(sm)
    {
      display: none;
    }

    &.collapsed
    {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      height: 100%;
    }
  }
}

@at-root
{
  .outerHeaderWrapper
  {
    position: relative;
    z-index: 9999;
  }
}

.location-results
{
  height: 300px;
  overflow: scroll;
  padding-right: 15px;
  padding-top: 20px;

  &::-webkit-scrollbar
  {
    appearance: none;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb
  {
    border-radius: 4px;
    background-color: $brand-quaternary;
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  @include bpgte(md)
  {
    height: 360px;
  }
}

.location-grid-results
{
  @include bpgte(md)
  {
    overflow: scroll;
    padding-right: 15px;
    padding-top: 20px;
    height: 360px;

    &::-webkit-scrollbar
    {
      appearance: none;
      width: 10px;
    }

    &::-webkit-scrollbar-thumb
    {
      border-radius: 4px;
      background-color: $brand-quaternary;
      box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    }
  }
}

.location-results-container
{
  margin-top: 10px;
}

.state-results-header
{
  background-color: $brand-tertiary;
  padding-top: 10px;
  padding-bottom: 10px;

  @include bplte(sm)
  {
    display: inline-block;
    height: auto;
    width: 100%;
  }
}

.dir-description
{
  margin-top: 5px;

  @include bplte(xs)
  {
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 600;
  }
}

.search-button
{
  @include button-primary;
}

.num-results
{
  @include font-h2;
  color: white;
  text-transform: uppercase;
  padding-top: 10px;

  @include bpgte(md)
  {
    text-align: center;
  }

  @include bplte(sm)
  {
    padding-left: 15px;
    float: left;
    display: inline;
  }

  @include bplte(xs)
  {
    display: block;
    width: 100%;
    margin-bottom: 0;
  }
}

.certona-container
{
  overflow: hidden;
}

.show-map-text
{
  float: right;
  color: white;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

.show-map-icon
{
  float: right;
  padding-right: 20px;
  padding-top: 10px;
  font-weight: $font-weight-bold;

  @include link-states
  {
    color: white;
    text-decoration: none;
  }

  @include fa-icon-before($fa-var-minus)
  {
    color: white;
    margin-right: 10px;
  }

  &::after
  {
    content: "Hide Map";
  }

  &.is-collapsed
  {
    @include fa-icon-before($fa-var-plus)
    {
      color: white;
      margin-right: 10px;
    }

    &::after
    {
      content: "Show Map";
    }
  }

  @include bplte(xs)
  {
    float: left;
    padding-top: 0;
    padding-left: 15px;
  }
}

.map-init
{
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.map-gone
{
  display: none;
}

.collapse-map
{
  width: 100%;
}

.Coupon
{
  &-container
  {
    display: flex;
    position: relative;
    bottom: 9px;
    right: 7px;

    @include bplte(xs)
    {
      margin-left: 10px;
    }

    .mk-1,
    .mk-2
    {
      @include bp(sm,sm)
      {
        margin-bottom: 10px;
      }
    }
  }

  &-row
  {
    display: flex;
    width: 100%;

    @include bplte(md)
    {
      overflow-x: scroll;
    }

    @include bplte(xs)
    {
      text-align: center;
      white-space: nowrap;
    }

    @include bpgte(lg)
    {
      justify-content: center;
    }
  }

  &-imageWrapper
  {
    display: flex;

    @include bpgte(lg)
    {
      width: 25%;
    }

    @include bplte(xs)
    {
      margin-bottom: 20px;
      min-width: 220px;
      justify-content: center;
      align-items: center;

      &:not(:last-child)
      {
        margin-right: 10px;
      }
    }
  }

  &-image
  {
    width: 275px;
    height: 275px;
    max-width: 275px;
    max-height: 275px;
    margin-left: auto;
    margin-right: auto;

    @include bplte(xs)
    {
      height: 220px;
      min-width: 220px;
    }
  }
}

.ios-click
{
  background-color: transparent;
  position: absolute;

  @include bplte(xs)
  {
    width: 100px;
    height: 30px;
    left: 0;
    margin-left: -220px;
    margin-top: -68px;
  }

  @include bpgte(sm)
  {
    width: 145px;
    height: 45px;
    right: 0;
    margin-right: 190px;
    margin-top: 485px;
  }

  @include bpgte(md)
  {
    width: 85px;
    height: 25px;
    right: 0;
    margin-right: 107px;
    margin-top: 154px;
  }

  @include bpgte(lg)
  {
    width: 100px;
    height: 30px;
    right: 0;
    margin-right: 105px;
    margin-top: 152px;
  }
}

.android-click
{
  background-color: transparent;
  position: absolute;

  @include bplte(xs)
  {
    width: 85px;
    height: 30px;
    left: 0;
    margin-left: -105px;
    margin-top: -68px;
  }

  @include bpgte(sm)
  {
    width: 130px;
    height: 45px;
    right: 0;
    margin-right: 36px;
    margin-top: 485px;
  }

  @include bpgte(md)
  {
    width: 70px;
    height: 25px;
    right: 0;
    margin-right: 26px;
    margin-top: 154px;
  }

  @include bpgte(lg)
  {
    width: 85px;
    height: 30px;
    right: 0;
    margin-right: 14px;
    margin-top: 152px;
  }
}

.c-location-grid-col
{
  width: 100%;
}

.c-location-grid-item-title
{
  text-transform: uppercase;
}

.c-location-grid-item-link
{
  margin-bottom: 10px;

  &::after
  {
    display: none;
  }

  &-wrapper
  {
    a
    {
      text-align: right;
      text-transform: uppercase;

      @include weight-and-color($font-weight-bold, black);
    }
  }
}

.c-phone-number-link
{
  color: black;
}

.c-directory-list
{
  @include bplte(sm)
  {
    margin-top: 30px;
  }
}

.loc-grid-store-tag
{
  float: left;
  margin-right: 5px;
  margin-left: 25px;
}

.c-map-with-pins
{
  @include bpgte(sm)
  {
    height: 100%;
  }
  width: 100%;

  .Teaser-linksRow,
  .Teaser .LocationName::before
  {
    display: none;
  }
}

.c-location-hours-today-day-hours
{
  font-weight: $font-weight-bold;
  font-size: 16px;
  float: left;
  display: inline;
}

.c-location-hours-today-day-status
{
  margin-right: 5px;
  font-size: 16px;
  float: left;
  text-transform: uppercase;
}

.searchbox
{
  @include mobile-margins;
}

.big-coupon-container
{
  @include bplte(xs)
  {
    width: 320px;
    margin: 0 auto;
    margin-top: 10px;
  }
}

.full-bleed-hr
{
  // TODO: Delete this
  width: 100%;
}

//Override client CSS
.row
{
  width: auto;
  max-width: none;
}

//Override client CSS
.columns
{
  padding-left: 0;
}

//Override client CSS
ul
{
  margin-left: 0;
}

.leaflet-popup-content
{
  min-width: 330px;
  width: 100% !important;
}

.search-label
{
  @include font-h4;
  text-transform: uppercase;
  display: block;

  @include bplte(sm)
  {
    margin-top: 20px;
  }
}

.search-button
{
  font-weight: $font-weight-bold;

  @include button-primary;

  padding: 9px 25px;
  margin-left: $grid-gutter-width*1.5;

  @include bplte(xs)
  {
    margin-left: 0;
    width: 100%;
  }
}

.search-input
{
  background-color: white;
  width: 270px;
  height: 40px;
  text-transform: uppercase;
  padding-left: $grid-gutter-width;
  color: #767676;
  font-family: $font-family-main;
  font-weight: $font-weight-bold;

  @include bplte(xs)
  {
    width: 100%;
  }

  &::placeholder
  {
    color: #767676;

    font-family: $font-family-main;
  }
}

.c-description
{
  button
  {
    @include weight-and-color($font-weight-bold, black);
  }
}

.c-description-full
{
  button
  {
    @include fa-icon-before($fa-var-minus)
    {
      margin-right: 10px;
    }
  }
}

.c-description-truncate
 {
  button
  {
    @include fa-icon-before($fa-var-plus)
    {
      margin-right: 10px;
    }
  }
}

.c-description-visible
{
  &-read-more
  {
    &::before
    {
      content: "\F067";
      font-family: 'FontAwesome';
      font-size: inherit;
      margin-right: 0.625rem;
    }
  }
}

.not-link
{
  color: $text-primary;
}

.back-to-top-image
{
  position: fixed;
  right: 10px;
  top: 130px;
  display: none;

  @include bpgte(sm)
  {
    display: none;
  }
}

.connectHeader
{
  position: relative;
  z-index: 999;
}

.filter-hidden
{
  display: none;
}

.banner-promo-item,
.appliance-promo-item
{
  padding: 0 10px;
  width: 700px;

  @include bpgte(md)
  {
    width: 1268px;
  }
}
