/** @define Teaser */

.Teaser
{
  display: flex;
  flex-direction: column;

  &-links
  {
    display: flex;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &-phone
  {
    /* postcss-bem-linter: ignore */
    .c-phone-label
    {
      display: none;
    }

    @include bpgte(sm)
    {
      margin-top: 14px;
      margin-bottom: 10px;
    }
  }

  &-linksRow
  {
    display: flex;
    justify-content: space-between;
    margin-bottom: 19px;

    .Teaser-links
    {
      margin-top: 0;
      margin-bottom: 0;
    }

    .Teaser-services
    {
      display: flex;
      flex-direction: column;
      max-width: 260px;

      @include bplte(xs)
      {
        display: none;
      }
    }

    @include bplte(xs)
    {
      justify-content: flex-end;
      margin-top: -20px;
    }
  }

  &-address
  {
    margin-top: 10px;
    width: 50%;
  }

  &-directions
  {
    margin-right: 2rem;
  }

  &-title
  {
    @include font-sizes(16px, 20px);
    display: flex;
    justify-content: space-between;
    color: $locator-black;
    margin-top: 0;

    .Teaser-titleLink
    {
      font-weight: $font-weight-bold;
      text-decoration: none;
      color: $locator-black;
    }

    .Teaser-miles
    {
      @include font-sizes(14px, 20px);
      font-weight: $font-weight-normal;
      color: $footer-black;
    }
  }

  .LocationName
  {
    /* postcss-bem-linter: ignore */
    &::before
    {
      content: counter(resultCount, decimal) ". ";
    }
  }

  @include font-sizes(14px, 20px);

  .Teaser-servicesList
  {
    display: flex;
    flex-direction: column;
    grid-column-gap: 40px;
    flex-wrap: wrap;
    max-height: 120px;

    &.is-hidden
    {
      display: none;
    }
  }

  .Teaser-service
  {
    max-width: 110px;
  }

  .Teaser-servicesLabel
  {
    display: flex;
    align-items: center;
    grid-column-gap: 5px;

    /* postcss-bem-linter: ignore */
    img
    {
      outline: none;
    }

    /* postcss-bem-linter: ignore */
    .rotated
    {
      transform: rotate(180deg);
    }
  }

  &-addressCTA
  {
    @include bpgte(md)
    {
      display: none;
    }

    /* postcss-bem-linter: ignore */
    &:hover
    {
      text-decoration: none;
    }
  }

  &-addressEl
  {
    @include bplte(sm)
    {
      display: none;
    }
  }

  /* postcss-bem-linter: ignore */
  .c-address
  {
    margin-bottom: 12px;

    @include bpgte(sm)
    {
      margin-bottom: 0;
    }
  }

  /* postcss-bem-linter: ignore */
  .c-get-directions
  {
    @include bplte(sm)
    {
      display: none;
    }
  }
}
