
$bpmin: sm !default;
$bpmax: xs !default;

.Footer
{
  font-family: $font-family-header_footer;
  background-color: $brand-light-gray2;
  color: $brand-gray;
  margin-top: 16px;
  padding-bottom: 24px;

  ul
  {
    margin-bottom: 0; //override base styling
  }

  a:visited
  {
    color: $footer-black;
  }

  @include bplte($bpmax)
  {
    padding-left: 16px;
    padding-right: 16px;
  }

  @include bplte($bpmax)
  {
    padding-top: 27px;
  }

  &-mainLinksWrapper
  {
    background-color: $brand-light-gray2;
    display: flex;
    justify-content: center;
    padding-bottom: 32px;

    @include bpgte($bpmin)
    {
      padding-top: 32px;
    }
  }

  &-mainLinks
  {
    display: flex;

    @include bplte($bpmax)
    {
      width: 100%;
      flex-direction: column;
      border-top: $divider-light-gray;
      margin-left: -16px;
      margin-right: -16px;
    }
  }

  &-column
  {
    color: $brand-gray;

    @include bpgte(sm)
    {
      width: 50%;
    }

    @include bpgte(md)
    {
      width: 25%;
    }
  }

  &-columnTitle
  {
    @include Text--footer;
    @include Text--bold;

    margin-bottom: 12px;

    @include bplte($bpmax)
    {
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: $divider-black;
    }

    &--mobile
    {
      width: 100%;
      text-align: center;

      @include bpgte($bpmin)
      {
        display: none;
      }
    }

    &--desktop
    {
      @include bplte($bpmax)
      {
        display: none;
      }
    }
  }

  &-list
  {
    &.collapse
    {
      @include bpgte($bpmin)
      {
        display: block !important; //to override bootstrap's collapse styling
      }
    }

    &--rowMobile
    {
      @include bplte($bpmax)
      {
        display: flex;
        justify-content: center;
      }
    }

    &--row
    {
      display: flex;
    }
  }

  &-listItem
  {
    margin-bottom: 12px;

    @include bplte($bpmax)
    {
      text-align: center;
    }

    &--bottom .Footer-link
    {
      @include Text--footerSmall;
    }

    &--bottom:nth-child(-n+4) .Footer-link
    {
      @include Text--bold;
    }
  }

  &-link
  {
    @include Text--footer;
    @include Link--footer;
  }

  /* postcss-bem-linter: ignore */
  a.Footer-link
  {
    // needed to deal with specificity issue causing some links
    // to still be red
    color: $footer-black;
  }

  &-social
  {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  &-socialIcon
  {
    margin-right: 8px;
    height: 35px;
    width: 35px;

    @include bplte($bpmax)
    {
      margin-right: 16px;
      margin-top: 10px;
      margin-bottom: 10px;
      height: 32px;
      width: 32px;
    }
  }

  &-downloadApp
  {
    margin-left: 20px;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  &-downloadAppIcon
  {
    height: 22px;
    width: 13px;
    margin-right: 12px;
  }

  &-bottom
  {
    background-color: white;
    padding-bottom: 36px;
  }

  &-bottomLinks
  {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &-listItem
  {
    &--bottom:not(:last-child)
    {
      margin-right: 20px;
    }
  }

  &-copyright
  {
    @include font-sizes(11px, 17px);

    color: $brand-gray2;
    text-align: center;
  }
}
