// TODO: Please run componentlib and modify this file to take advantage of the util-mixins component.
// Background

@mixin word-wrap()
{
  word-break: break-word;
  hyphens: auto;
}

@mixin base-bottom-border()
{
  border-bottom-color: $gray-4;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

@mixin button($background-normal, $background-hover, $text-normal, $text-hover)
{
  background-color: $background-normal;
  color: $text-normal;
  padding: 12px 25px;
  display: inline-block;
  text-align: center;
  border: none;
  text-transform: uppercase;

  &:link,
  &:visited,
  &:active
  {
    color: $text-normal;
    text-decoration: none;
  }

  &:hover
  {
    background-color: $background-hover;
    color: $text-hover;
    text-decoration: none;
  }
}

@mixin button-primary
{
  @include button($brand-primary, $brand-octenary, white, white);
  border-radius: 10px;
}

@mixin button-secondary
{
  @include button($brand-primary, $brand-primary, white, white);
}

@mixin hideBR
{
  br
  {
    content: ' ';

    &::after
    {
      content: ', ';
    }
  }
}

@mixin break($pseudo:'after')
{
  &:#{$pseudo}
  {
    content: "\000A";
    white-space: pre;
  }
}

@mixin break-after
{
  @include break();
}

@mixin break-before
{
  @include break('before');
}

@mixin fa-icon($placement, $icon, $font-size:inherit)
{
  &:#{$placement}
  {
    content: $icon;
    font-family: 'FontAwesome';
    font-size: $font-size;

    @content;
  }
}

@mixin fa-icon-before($icon, $font-size:inherit)
{
  @include fa-icon(before, $icon, $font-size) { @content; }
}

@mixin fa-icon-after($icon, $font-size:inherit)
{
  @include fa-icon(after, $icon, $font-size) { @content; }
}

@mixin link-states($selector:'&')
{
  #{$selector},
  #{$selector}:hover,
  #{$selector}:visited,
  #{$selector}:active,
  #{$selector}:link
  {
    @content;
  }
}

@mixin link-primary
{
  color: $link-color-primary;

  &:link
  {
    color: $link-color-primary;
  }

  &:visited
  {
    color: $link-color-primary;
  }

  &:hover
  {
    color: $link-color-hover;
  }

  &:active
  {
    color: $link-color-primary;
  }
}

@mixin link-secondary
{
  color: $link-color-secondary;

  &:link,
  &:hover
  {
    color: $link-color-secondary;
  }

  &:visited
  {
    color: $link-color-secondary-visited;
  }

  &:active
  {
    color: $link-color-secondary-active;
  }
}

@mixin link-tertiary
{
  @include link-states
  {
    color: $gray-2;
  }
}

@mixin border-style
{
  border: 1px solid $gray-1;
}

@function stripunits($value)
{
  @return $value / ($value * 0 + 1);
}

@function torem($value)
{
  @if type-of($value) == number and unit($value) == 'px'
  {
    $stripped: stripunits($value);
    $converted: ($stripped/16 * 1rem);

    @return $converted;
  }

  @else
  {
    @return #{$value};
  }
}

@function torelative($desired-size, $base-size)
{
  $valid-input: true;
  $desired-converted: $desired-size;

  @if type-of($desired-size) == number and not unitless($desired-size) and
    type-of($base-size)   == number and not unitless($base-size)
  {
    @if (unit($desired-size) == unit($base-size))
    {
      $desired-stripped: stripunits($desired-size);
      $base-stripped: stripunits($base-size);

      $desired-converted: $desired-stripped / $base-stripped;
    }

    @else
    {
      @warn "Units must be the same for them to be converted to unitless, relative values.";
      $valid-input: false;
    }

    @if $valid-input
    {
      @return #{$desired-converted};
    }
  }

  @else
  {
    @return #{$desired-converted};
  }
}

@mixin font-sizes($font-size, $line-height: 'normal', $font-size-xs: $font-size, $line-height-xs: $line-height)
{
  font-size: torem($font-size-xs);
  line-height: torelative($line-height-xs, $font-size-xs);

  @if $font-size != $font-size-xs
  {
    @include bpgte(sm)
    {
      font-size: torem($font-size);
    }
  }

  @if $line-height != $line-height-xs
  {
    @include bpgte(sm)
    {
      line-height: torelative($line-height, $font-size);
    }
  }
}

@mixin weight-and-color($font-weight, $font-color)
{
  font-weight: $font-weight;
  color: $font-color;
}

@mixin font-base
{
  @include font-sizes(14px, 18px);
  font-family: $font-family-main;
  font-weight: $font-weight-normal;
  color: $text-primary;
}

@mixin font-base-fff
{
  // TODO: If the only thing that's changing is the color, just set that.
  // The font-size, line-height, and weight will just inherit from font-base.
  // Please make sure that you understand:
  // http://www.smashingmagazine.com/2007/07/css-specificity-things-you-should-know/
  // and http://www.smashingmagazine.com/2010/04/css-specificity-and-inheritance/
  @include font-sizes(14px, 18px); // TODO: if you're not changing this don't set them here.
  font-family: $font-family-main; // TODO: if you're not changing this don't set them here.
  font-weight: $font-weight-normal; // TODO: if you're not changing this don't set them here.
  color: white;
}

@mixin font-base-link
{
  @include font-sizes(14px, 18px); // TODO: if you're not changing this don't set them here.
  font-family: $font-family-main; // TODO: if you're not changing this don't set them here.
  font-weight: $font-weight-normal; // TODO: if you're not changing this don't set them here.
  color: $brand-primary;
}

@mixin font-large
{
  @include font-sizes(20px, 26px);
  font-family: $font-family-main; // TODO: if you're not changing this don't set them here.
  font-weight: $font-weight-bold;
  color: $text-primary; // TODO: if you're not changing this don't set them here.
}

@mixin font-small
{
  @include font-sizes(12px, 18px);
  font-family: $font-family-main; // TODO: if you're not changing this don't set them here.
  font-weight: $font-weight-normal;
  color: $text-primary; // TODO: if you're not changing this don't set them here.
}

@mixin font-xsmall // TODO: This apperas to be indentical to font-small, seems like we should delete it.
{
  @include font-sizes(12px, 18px);
  font-family: $font-family-main;
  font-weight: $font-weight-normal;
  color: $text-primary;
}

@mixin font-h1
{
  @include font-sizes(32px, 34px, 20px, 21px);
  font-family: $font-family-main; // TODO: if you're not changing this don't set them here.
  font-weight: $font-weight-normal;
  color: $text-primary; // TODO: if you're not changing this don't set them here.
  text-transform: uppercase;
}

@mixin font-h2
{
  @include font-sizes(16px, 18px);
  font-family: $font-family-main; // TODO: if you're not changing this don't set them here.
  font-weight: $font-weight-bold;
  color: $text-primary; // TODO: if you're not changing this don't set them here.
}

@mixin font-h3
{
  @include font-sizes(14px, 15px);
  font-family: $font-family-main; // TODO: if you're not changing this don't set them here.
  font-weight: $font-weight-bold;
  color: $brand-quaternary;
}

@mixin font-h3-link
{
  @include font-sizes(16px, 18px);
  font-family: $font-family-main; // TODO: if you're not changing this don't set them here.
  font-weight: $font-weight-bold;
  color: $brand-primary;
}

@mixin font-h4
{
  @include font-sizes(18px, 36px);
  font-family: $font-family-main; // TODO: if you're not changing this don't set them here.
  font-weight: $font-weight-bold;
  color: $text-primary; // TODO: if you're not changing this don't set them here.
}

@mixin font-h5
{
  @include font-sizes(46px, 52px);
  font-family: $font-family-main; // TODO: if you're not changing this don't set them here.
  font-weight: $font-weight-bold;
  color: $text-primary; // TODO: if you're not changing this don't set them here.
}

@mixin font-awesome
{
  @include font-sizes(36px, 36px);
  font-weight: $font-weight-normal;
  color: $text-primary;
}

@mixin font-header
{
  font-weight: $font-weight-normal;
  font-family: $font-family-secondary;
  color: $text-primary;
}

@mixin font-header-sm
{
  @include font-header;
  @include font-sizes(11px, 16.5px);
}

@mixin font-header-md
{
  @include font-header;
  @include font-sizes(12px, 18px);
}

@mixin font-header-lg
{
  @include font-header;
  @include font-sizes(14px, 21px);
}

@mixin font-menu
{
  @include font-sizes(12px, 16px);
  font-weight: $font-weight-bold;
  text-transform: uppercase;
  color: $gray-2;
}

@mixin vertical-center
{
  top: 50%;
  transform: translateY(-50%);
}

@mixin bp($min, $max)
{

  $MediaQueriesEnabled: true !default;

  @if $MediaQueriesEnabled
  {
    $bps: xs, sm, md, lg;
    $valid-input: true;
    $mq: "";

    $maxes: (
      xs: "(max-width: #{$screen-xs-max})",
      sm: "(max-width: #{$screen-sm-max})",
      md: "(max-width: #{$screen-md-max})"
    );

    $mins: (
      sm: "(min-width: #{$screen-sm-min})",
      md: "(min-width: #{$screen-md-min})",
      lg: "(min-width: #{$screen-lg-min})"
    );

    @if ($min != null and $max != null)
    {
      @if index($bps, $min) and index($bps, $max)
      {
        $mq: "#{map-get($mins, $min)} and #{map-get($maxes, $max)}";
      }

      @else
      {
        @warn "Breakpoint parameters must be either xs, sm, md, or lg.";
        $valid-input: false;
      }
    }

    @else if ($min != null and $max == null)
    {
      @if $min == xs
      {
        @warn "There is no min-width for Mobile Screens.";
        $valid-input: false;
      }

      @else
      {
        $mq: "#{map-get($mins, $min)}";
      }
    }

    @else if ($min == null and $max != null)
    {
      @if $max == lg
      {
        @warn "There is no Max Width for Large Screens.";
        $valid-input: false;
      }

      @else
      {
        $mq: "#{map-get($maxes, $max)}";
      }
    }

    @else
    {
      @warn "You must pass a parameter to the bp() mixin!";
      $valid-input: false;
    }

    @if $valid-input
    {
      @media #{$mq} { @content; }
    }
  }
}

@mixin bplte($max)
{
  @include bp(null, $max)
  {
    @content;
  }
}

@mixin bpgte($min)
{
  @include bp($min, null)
  {
    @content;
  }
}

@mixin mobile-margins
{
  @include bplte(xs)
  {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@mixin ul_styling
{
  list-style-type: initial;
  list-style-position: inside;
}

@mixin ol_styling
{
  list-style-type: decimal;
  list-style-position: inside;
}

@mixin strong_styling
{
  font-weight: $font-weight-bold;
}

@mixin em_styling
{
  font-style: italic;
}

@mixin rich_text_formatting
{
  ul,
  ol
  {
    display: flex;
    flex-direction: column;
    margin-left: 8px;

    li
    {
      list-style-position: outside;
      margin-left: 16px;
    }
  }

  ul
  {
    @include ul_styling;
  }

  ol
  {
    @include ol_styling;
  }

  strong
  {
    @include strong_styling;
  }

  em
  {
    @include em_styling;
  }

  p
  {
    margin-top: 8px;
  }
}
