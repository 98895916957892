//
// Theme.scss
//
// This file should include styles for theming a particular design. This is
// where custom images, custom colors, custom fonts, and other such 'theme'
// based styles should appear. We will use this for our generic templates, but
// probably not as much for the highly customized pages projects.
//

.StorePages-body
{
  .c-map-with-pins
  {
    @include bpgte(md)
    {
      margin-top: 10px;
    }
  }

  .search-wrapper
  {
    @include bpgte(md)
    {
      margin-top: 20px;
    }
  }

  .location-grid-results
  {
    padding-top: 0;
  }

  .nearby-locations-wrapper
  {
    margin: 10px;
    margin-bottom: 30px;
    text-transform: uppercase;
    list-style-type: none;
    //Client CSS Override
    ul
    {
      font-size: inherit;
    }

    .nearby-index-geomodifier
    {
      font-weight: $font-weight-bold;
      font-size: 16px;
    }

    .nearby-locations-list
    {
      list-style-type: none;
      margin-top: 30px;
    }
  }

  .nearbyLocation
  {
    margin-bottom: 10px;
    border-bottom: $divider-light;
    border-spacing: 5px;

    .c-location-hours-today-day-status
    {
      font-weight: $font-weight-bold;
    }

    .searcher-distance
    {
      font-size: 10px;
      line-height: 14px;
      font-weight: $font-weight-normal;
      padding: 5px;
      background-color: $brand-septenary;
      float: right;
      margin-right: 10px;

      @include bplte(xs)
      {
        float: none;
        display: block;
        width: 50px;
        margin-left: 15px;
        margin-top: 5px;
      }
    }

    .openToday
    {
      margin-top: 15px;
      margin-bottom: 15px;
      margin-left: 15px;

      .c-location-hours-today-day-hours
      {
        float: none;
      }
    }

    .nearby-address-phone
    {
      padding-left: 15px;
    }

    .store-tag
    {
      display: inline;
    }

    .c-phone-label
    {
      display: inline;
    }

    .c-phone-number-wrapper
    {
      display: inline;
    }

    .c-phone-number
    {
      display: inline;
    }

    .c-phone-number-link
    {
      &:link,
      &:visited,
      &:active,
      &:hover
      {
        color: black;
      }
    }

    .nearby-links-wrapper
    {
      @include bpgte(sm)
      {
        float: right;
        text-align: right;
        margin-right: 10px;
      }

      @include bplte(xs)
      {
        padding-left: 15px;
        margin-top: 15px;
      }

      a
      {
        color: black;
        font-weight: $font-weight-bold;

        @include bplte(xs)
        {
          font-size: 14px;
        }
      }

      .nearby-directions-link
      {
        @include bplte(xs)
        {
          margin-right: 25px;
        }
      }

      .nearby-details-link
      {
        margin-top: 10px;

        @include bpgte(sm)
        {
          display: block;
        }
      }
    }
  }

  .leaflet-popup-close-button
  {
    display: none;
  }

  .leaflet-popup-content-wrapper
  {
    color: black;
    border-radius: 5px;
    text-transform: uppercase;

    .leaflet-popup-content
    {
      padding: 0;
    }

    .nearby-index-geomodifier
    {
      background-color: $brand-senary;
      padding: 10px;

      .nearby-name
      {
        @include font-small;
        color: $brand-primary;
        font-weight: $font-weight-bold;
      }

      .searcher-distance
      {
        float: right;
        padding: 5px;
        margin-top: -5px;
        background-color: $brand-septenary;
      }
    }

    .openToday
    {
      padding-left: 10px;
    }

    .c-location-hours-today-day-status,
    .c-location-hours-today-day-hours
    {
      font-size: 12px;
      font-weight: $font-weight-bold;
      margin-top: 5px;
    }

    .nearby-address-phone
    {
      padding-left: 10px;
      margin-top: 5px;
      margin-bottom: 10px;
    }

    .c-phone-label
    {
      display: inline;
    }

    .c-phone-number-wrapper,
    .c-phone-number
    {
      display: inline;
    }

    .c-phone
    {
      margin-top: 5px;
    }

    .c-phone-number-link
    {
      &:link,
      &:visited,
      &:active,
      &:hover
      {
        color: black;
      }
    }

    .links-section
    {
      margin-left: 0;
    }

    .links-section .nearby-links-wrapper
    {
      text-align: right;

      a:link,
      a:visited,
      a:active,
      a:hover
      {
        color: black;
        font-weight: $font-weight-bold;
      }

      .nearby-details-link
      {
        display: block;
        margin-top: 10px;
      }
    }
  }
  // TODO: Delete all references to the c-location-grid
  .c-location-grid-item
  {
    padding-left: 15px;
    padding-bottom: 15px;

    &-title
    {
      line-height: 18px;
      margin-bottom: 5px;
    }

    &-address
    {
      display: inline;
      padding-left: 25px;
      text-transform: uppercase;

      .c-address
      {
        display: block;
        margin-bottom: 10px;
      }

      .c-address-street-1
      {
        padding-left: 25px;
      }
    }

    &-phone
    {
      display: inline;

      a
      {
        text-transform: uppercase;
        color: black;
      }
    }

    &-hours-today
    {
      display: inline;
      text-transform: uppercase;
      font-weight: $font-weight-bold;
    }

    &-link-wrapper
    {
      margin-top: -45px;
      margin-bottom: 20px;
      display: inline-block;
      float: right;
    }
  }

  .leaflet-popup-content
  {
    .c-location-hours-today-details-row.is-today
    {
      display: inline-block;
      color: black;
    }

    .c-location-grid-item-link-wrapper
    {
      padding-top: 5px;
    }

    .loc-grid-store-tag
  {
      display: none;
    }

    .c-phone-number-link
    {
      font-weight: $font-weight-normal;
      font-family: $font-family-main;
    }

    .c-location-grid-item-phone
    {
      &::before
      {
        content: 'STORE: ';
      }
      padding-left: 25px;
    }

    .nearby-title
    {
      a
      {
        font-weight: $font-weight-bold;
      }
    }
  }

  .c-phone-number
  {
    display: inline;
  }

  .Coupon
  {
    &-container
    {
      margin-top: 10px;
    }

    &-image
    {
      max-width: 275px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .location-num
  {
    display: inline;
    margin-right: 5px;
  }

  span[itemprop='addressLocality']
  {
    padding-left: 25px;
  }

  .Directory
  {
    &-listLinkCount
    {
      margin-left: 6px;
    }
  }
}

.HomeServices-body
{
  .Directory
  {
    &--ace
    {
      padding-bottom: 4rem;

      @include bplte(xs)
      {
        padding-bottom: 20px;
      }
    }

    &-row
    {
      display: flex;
      flex-wrap: wrap;
      margin-left: -1rem;
      margin-right: -1rem;
    }

    &-title
    {
      width: 100%;
      padding-left: 1rem;
      padding-right: 1rem;
      margin-top: 2rem;
    }

    &-content
    {
      margin-top: 2rem;
    }

    &-listTeaser
    {
      @include bplte(xs)
      {
        width: 100%;
        margin-left: 1rem;
        margin-right: 1rem;

        &:not(:last-child)
        {
          border-bottom: $border-default;
          padding-bottom: 2rem;
          margin-bottom: 2rem;
        }
      }

      @include bpgte(sm)
      {
        width: 50%;
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 2rem;
      }

      @include bpgte(md)
      {
        width: 25%;
      }
    }

    &-listLinks
    {
      @include bp(sm, sm)
      {
        column-count: 2;
      }

      @include bp(md, md)
      {
        column-count: 3;
      }

      @include bpgte(lg)
      {
        column-count: 4;
      }
    }

    &-listLink
    {
      display: inline-block;
      margin-bottom: 20px;
    }

    &-listLinkCount
    {
      margin-left: 0.5rem;
      display: inline-block;
    }
  }
}
