/** @define Directory */

.Directory
{
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: auto;
  height: 100%;
  font-family: $font-family-header_footer;

  @include bplte(sm)
  {
    flex-direction: column;
  }

  &-container
  {
    @include l-container;
    padding: 5px;

    @include bplte(xs)
    {
      padding-left: 0;
      padding-right: 0;
    }

    @include bpgte(sm)
    {
      width: 768px;
    }

    @include bpgte(md)
    {
      width: $container-desktop-base;
    }

    @include bpgte(lg)
    {
      width: $container-large-desktop-base;
    }

    @media only screen and (min-width: 1300px)
    {
      width: $container-xl-desktop-base;
    }
  }

  &-banner--mobile
  {
    padding: 0 16px;

    @include bpgte(md)
    {
      display: none;
    }
  }

  &-banner--tablet
  {
    display: none;

    @include bp(md, md)
    {
      display: flex;
    }
  }

  &.is-overflowHidden
  {
    overflow-y: hidden;
  }

  &-resultsWrapper
  {
    margin-bottom: 10px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    overflow: auto;
    padding-bottom: 64px;

    &--margin
    {
      @include bpgte(lg)
      {
        margin-top: 54px;
      }

      @include bplte(xs)
      {
        margin-top: 54px;
      }
    }
  }

  &-stateListContainer,
  &-cityListContainer,
  &-locationListContainer
  {
    @include bpgte(md)
    {
      position: relative;
      right: 7px;
      bottom: 3px;
    }
  }

  &-title
  {
    @include font-sizes(20px, 28px);
    color: $locator-black;
    font-weight: $font-weight-bold;
    padding: 0 16px;
    padding-bottom: 0;
    text-transform: none;
    width: 100%;
    margin: 0;

    @include bplte(md)
    {
      text-align: center;
    }
  }

  &-searchWrapper
  {
    position: sticky;
    z-index: 1;
    padding: 10px;
    display: flex;
    flex-shrink: 0;
    width: 100%;
    top: 0;
    background-color: $white;

    @include bplte(xs)
    {
      padding: 16px;
    }

    @include bplte(md)
    {
      display: flex;
      flex-direction: column;
    }
  }

  &-form
  {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-wrap: wrap;

    @include bplte(xs)
    {
      justify-content: center;
    }

    @include bp(sm, md)
    {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &-formBordered
  {
    max-width: 309px;
    border: $border-default;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include bplte(xs)
    {
      width: 247px;
    }

    @include bp(sm, sm)
    {
      width: 400px;
      max-width: none;
    }
  }

  &-searchTop
  {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    @include bplte(md)
    {
      justify-content: center;
    }

    @include bp(sm, md)
    {
      padding-right: 24px;
      max-height: 48px;
      margin-bottom: 30px;
    }
  }

  &-buttons
  {
    display: flex;
  }

  &-button
  {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 8px;
    padding-right: 8px;

    &[disabled]
    {
      background-color: $gray-12;
      cursor: default;
    }

    &:not(:last-child)
    {
      margin-right: 1px;
    }

    &:not(:first-child)
    {
      margin-left: 1px;
    }

    /* postcss-bem-linter: ignore */
    .icon
    {
      width: 18px;
      height: 18px;
    }

    /* postcss-bem-linter: ignore */
    .icon-geolocate_alt
    {
      width: 16px;
      height: 24px;
    }
  }

  &-geoButton
  {
    display: flex;
    align-items: center;
    margin-left: 15px;
    width: 24px;
  }

  &-buttonRow
  {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 52px;
    padding-top: 20px;
    background-color: $white;

    @include bpgte(lg)
    {
      display: none;
    }
  }

  &-buttonWrapper
  {
    padding: 0 37px;
  }

  &-mobileListButton
  {
    @include font-sizes(16px, 20px);
    padding: 4px 8px;
    border-bottom: $border-red;
  }

  &-mobileMapButton
  {
    @include font-sizes(16px, 20px);
    padding: 4px;
  }

  /* postcss-bem-linter: ignore */
  &-filterFieldsWrapper
  {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s, opacity 0.3s linear;
    z-index: 9999999;
    background-color: $white;

    @include bpgte(sm)
    {
      left: calc(100% - 219px);
      top: 50%;
      bottom: auto;
      right: auto;
      transform: translate(-50%, -50%);
      height: 100%;
      width: 439px;
    }

    /* postcss-bem-linter: ignore */
    svg
    {
      height: 16px;
      width: 16px;
    }
  }

  &-overlay
  {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $locator-black;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s linear, opacity 0.3s linear;
    z-index: 2;

    &.is-active
    {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s linear, opacity 0.3s linear;
    }
  }

  /* postcss-bem-linter: ignore */
  &-filters.is-active
  {
    /* postcss-bem-linter: ignore */
    .Directory-filterFieldsWrapper
    {
      visibility: visible;
      opacity: 1;
      transition: visibility 0s, opacity 0.3s linear;
    }
  }

  &-filterFields
  {
    width: 100%;

    @include bpgte(sm)
    {
      width: 439px;
      height: 100%;
    }
  }

  &-filtersCount
  {
    margin-left: 3px;
  }

  &-filtersButtonWrapper
  {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 68px;

    @include bplte(sm)
    {
      justify-content: center;
    }

    @include bpgte(sm)
    {
      margin-top: 0;
      max-width: 35%;
      width: 50%;
      padding-left: 24px;
      justify-content: space-between;
      margin-bottom: auto;
    }

    @include bpgte(md)
    {
      margin-top: 22px;
      max-width: none;
      width: auto;
      padding-left: 0;
      justify-content: flex-start;
      margin-bottom: 0;
      position: relative;
    }
  }

  &-filtersButton
  {
    @include font-sizes(16px, 20px);
    font-family: $font-family-header_footer;
    color: $text-search;
    background-color: $gray-9;
    padding: 8px 24px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: $filter-shadow;

    @include bp(sm, sm)
    {
      width: 100%;
    }

    @include bp(md, md)
    {
      margin-top: -45px;
    }
  }

  &-filtersLabelEndpoint
  {
    padding-left: 20px;

    @include bp(sm, sm)
    {
      padding-left: 0;
    }
  }

  &-filterClearButton
  {
    text-decoration: underline;
    padding: 10px 0 10px 20px;

    &:hover
    {
      text-decoration: none;
    }
  }

  &-search
  {
    padding: 0;
    margin-right: 15px;
    background-color: inherit;
    margin-bottom: 10px;

    @include bpgte(sm)
    {
      margin-bottom: 16px;
    }

    &:hover
    {
      background-color: inherit;
    }
  }

  &-input
  {
    margin-left: 16px;
    margin-top: 13px;
    margin-bottom: 15px;
    padding: 0;
    height: auto;
    background-color: $white;
    text-transform: none;
  }

  &-row
  {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &-list
  {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 0;
    overflow: auto;
  }

  &-listTeaser
  {
    @include bplte(xs)
    {
      width: 100%;
      margin-left: 16px;
      margin-right: 16px;

      &:not(:last-child)
      {
        border-bottom: $border-default;
        padding-bottom: 32px;
        margin-bottom: 32px;
      }
    }

    @include bpgte(sm)
    {
      width: 50%;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 32px;
    }

    @include bpgte(md)
    {
      width: 25%;
    }
  }

  &-contents,
  &-content
  {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    background-color: $white;

    @include bpgte(md)
    {
      position: relative;
      bottom: 0;
      height: auto;
      width: 454px;
    }
  }

  &-content
  {
    @include bpgte(lg)
    {
      height: 460px;
      overflow-y: scroll;

      /* postcss-bem-linter: ignore */
      &::-webkit-scrollbar
      {
        -webkit-appearance: none;
        width: 8px;
        height: 72px;
      }

      /* postcss-bem-linter: ignore */
      &::-webkit-scrollbar-thumb
      {
        border-radius: 4px;
        background-color: $brand-quaternary;
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }
  }

  &-listItem
  {
    border-bottom: $border-locator-new;
    margin: 0;
    font-size: 16px;

    &:first-child
    {
      border-top: $border-locator-new;
    }

    &:hover,
    &.is-hovered
    {
      @include bpgte(md)
      {
        background-color: $gray-10;
      }
    }

    &.is-selected
    {
      background-color: $gray-10;
    }

    &--ordered
    {
      counter-increment: resultCount;
    }
  }

  &-listLinks
  {
    list-style-type: none;
    column-count: 1;
    padding-left: 0;
    line-height: 24px;
  }

  &-listLink
  {
    display: inline-block;
    padding: 16px 0;
    text-transform: none;
    color: $text-primary;
    font-weight: $font-weight-bold;
  }

  &-listArrow
  {
    float: right;
    width: 24px;
    height: 24px;
    margin-top: 16px;
  }

  &-listLinkCount
  {
    margin-left: 8px;
    display: inline-block;
  }

  &-topBannerWrapper
  {
    margin-bottom: 29px;
    display: none;

    @include bpgte(lg)
    {
      display: block;
    }
  }

  &-topBanner
  {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  &-resultsSummary
  {
    @include font-sizes(16px, 20px);
    padding: 0 0 4px;
    color: $gray-9;
  }

  &-numResults
  {
    @include font-sizes(16px, 20px);
    color: $gray-9;
    margin: 0;
  }

  /* postcss-bem-linter: ignore */
  &.mobile-map-visible &-resultsSummary
  {
    @include bplte(md)
    {
      border-bottom: $border-locator-new;
      padding-left: 0;
      padding-right: 0;
    }

    @include bp(sm, md)
    {
      padding-top: 0;
    }
  }

  /* postcss-bem-linter: ignore */
  &.mobile-map-visible &-map
  {
    visibility: visible;
  }

  &.mobile-map-visible &-list
  {
    @include bplte(xs)
    {
      margin-top: 315px;
    }

    @include bp(sm, md)
    {
      margin-top: 425px;
    }
  }

  &.mobile-map-visible &-list--banner
  {
    @include bplte(xs)
    {
      margin-top: 500px;
    }

    @include bp(sm, md)
    {
      margin-top: 610px;
    }
  }

  /* postcss-bem-linter: ignore */
  &.mobile-map-visible &-listItem:first-child
  {
    @include bplte(md)
    {
      border-top: none;
    }
  }

  /* postcss-bem-linter: ignore */
  &.mobile-map-visible &-topBannerWrapper--list
  {
    display: none;
  }

  /* postcss-bem-linter: ignore */
  &.mobile-map-visible &-mobileListButton
  {
    border-bottom: none;
  }

  /* postcss-bem-linter: ignore */
  &.mobile-map-visible &-mobileMapButton
  {
    border-bottom: $border-red;
  }

  /* postcss-bem-linter: ignore */
  .mapboxgl-ctrl-attrib.mapboxgl-compact::after
  {
    visibility: hidden;
  }

  /* postcss-bem-linter: ignore */
  &.mobile-map-visible .mapboxgl-ctrl-attrib.mapboxgl-compact::after
  {
    visibility: visible;
  }

  /* postcss-bem-linter: ignore */
  a
  {
    color: $locator-black;
    text-decoration: underline;
  }

  &-map
  {
    position: relative;
    top: 0;
    order: 2;
    max-width: 769px;
    max-height: 476px;
    width: 769px;
    height: 476px;
    margin-right: 79px;
    margin-bottom: 50px;

    @include bplte(xs)
    {
      height: 290px;
      margin-top: 260px;
    }

    @include bplte(sm)
    {
      height: 290px;
    }

    @include bp(sm, sm)
    {
      margin-top: 175px;
    }

    @include bp(sm, md)
    {
      height: 410px;
    }

    @include bplte(md)
    {
      position: absolute;
      z-index: 1;
      width: calc(100% - 32px);
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      visibility: hidden;
    }

    @include bp(md, md)
    {
      margin-top: 165px;
    }

    @include bpgte(lg)
    {
      display: block;
      margin-right: 0;
    }

    &--margin
    {
      @include bplte(xs)
      {
        margin-top: 210px;
      }
    }

    &--banner
    {
      @include bp(sm, sm)
      {
        margin-top: 320px;
      }
    }
  }

  /* postcss-bem-linter: ignore */
  .c-location-hours-collapse
  {
    /* postcss-bem-linter: ignore */
    .c-hours-toggle
    {
      display: flex;

      /* postcss-bem-linter: ignore */
      &::after
      {
        content: ' ';
        display: inline-block;
        height: 7px;
        width: 12px;
        transform: rotateX(180deg);
        margin-left: 4px;
        background-image: url('assets/images/down-arrow-black.svg');
        background-size: 12px 7px;
        margin-bottom: 7px;
      }

      &.collapsed.c-hide-desktop
      {
        @include bpgte(sm)
        {
          display: none;
        }
      }

      /* postcss-bem-linter: ignore */
      &.collapsed::after
      {
        transform: rotate(0);
      }

      &.c-hide-desktop,
      &.c-hide-desktop.collapsed
      {
        @include bpgte(sm)
        {
          display: flex;
        }
      }
    }

    .c-hours-collapse
    {
      @include bpgte(sm)
      {
        display: none;
      }
    }

    .c-hours-collapse.in
    {
      @include bpgte(sm)
      {
        display: block;
      }
    }

    /* postcss-bem-linter: ignore */
    .hidden-xs
    {
      display: none; //this is to not display the "Open Today..." line twice on desktop
    }

    /* postcss-bem-linter: ignore */
    .c-location-hours-today-day-status,
    .c-location-hours-today-day-hours
    {
      @include font-sizes(14px, 20px);
      font-weight: $font-weight-semibold;
      text-transform: none;
    }
  }

  /* postcss-bem-linter: ignore */
  .ResultList
  {
    @include bpgte(lg)
    {
      margin: 0;
      height: 454px;
      overflow-y: scroll;
      padding-right: 25px;

      /* postcss-bem-linter: ignore */
      &::-webkit-scrollbar
      {
        -webkit-appearance: none;
        width: 8px;
        height: 72px;
      }

      /* postcss-bem-linter: ignore */
      &::-webkit-scrollbar-thumb
      {
        border-radius: 4px;
        background-color: $brand-quaternary;
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
      }
    }

    /* postcss-bem-linter: ignore */
    &-item
    {
      border-bottom: $border-locator-new;
      padding: 16px 0 0;
      margin-left: 0;
    }

    /* postcss-bem-linter: ignore */
    &-item.is-selected
    {
      background-color: $gray-10;
    }

    /* postcss-bem-linter: ignore */
    &-item:first-child
    {
      border-top: $border-locator-new;
    }

    /* postcss-bem-linter: ignore */
    &-item &.mobile-map-visible
    {
      border: none;
    }
  }

  /* postcss-bem-linter: ignore */
  .Locator--ace
  {
    overflow-y: visible;
  }
}

/* postcss-bem-linter: ignore */
@media only screen and (min-device-width: 820px) and (max-device-width: 1180px) and (orientation: landscape)
{
  .Directory
  {
    justify-content: center;
  }
}

/* postcss-bem-linter: ignore */
.Locator-mobileButtonRow
{
  display: none;
}

/* postcss-bem-linter: ignore */
.Locator-searchTop
{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @include bplte(md)
  {
    justify-content: center;
  }

  @include bp(sm, md)
  {
    padding-right: 24px;
    max-height: 48px;
    margin-bottom: 30px;
  }
}
