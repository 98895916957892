// Header styling for all non HomeServices pages

.Header--default
{
  background-color: $header-red;
  display: flex;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 1rem;
  padding-right: 1rem;
  font-family: $font-family-header_footer;

  @include bplte(xs)
  {
    flex-wrap: wrap;
    order: 1;
  }
}

.Header--default .Header
{
  &-logoLink
  {
    display: flex;
    align-items: center;
  }

  &-logo
  {
    padding-right: 1rem;
    width: 130px;
  }

  &-logoWrapper
  {
    display: flex;

    @include bplte(xs)
    {
      width: 60%;
    }
  }

  &-form
  {
    background-color: white;
    flex-grow: 1;
    border: 1px solid $gray-6;
    border-radius: 2px;

    @include bplte(xs)
    {
      order: 3;
      margin-top: 0.5rem;
    }
  }

  &-searchButton
  {
    width: 40px;
  }

  &-searchInput
  {
    border: 0;
    color: #999;
    width: calc(100% - 40px);
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 16px;
    padding-right: 16px;

    @include font-sizes(16px, 24px);

    &::placeholder
    {
      font-family: $font-family-header_footer;
      font-style: italic;
    }
  }

  &-linksList
  {
    display: flex;
    margin: 0;
    align-items: center;

    @include bplte(xs)
    {
      width: 40%;
      order: 2;
    }
  }

  &-linkItem
  {
    padding-left: 1rem;
    padding-right: 1rem;
    border-right: 1px solid white;
    height: 40px;
    display: flex;
    align-items: center;

    &:last-child
    {
      border: 0;
      padding-right: 0;
    }

    a,
    a:visited,
    a:link
    {
      color: white;

      @include bplte(xs)
      {
        color: black;
      }
    }

    &--desktopOnly
    {
      @include bplte(xs)
      {
        display: none;
      }
    }

    &--mobileOnly
    {
      @include bpgte(sm)
      {
        display: none;
      }
    }
  }

  &-hamburger
  {
    margin-right: 10px;

    @include bpgte(sm)
    {
      display: none;
    }
  }

  &-hamburgerIcon
  {
    height: 36px;
    width: 26px;
  }

  &-link
  {
    &--block
    {
      display: block;
    }
  }

  &-cartText
  {
    @include bplte(xs)
    {
      display: none;
    }
  }

  &-cartIcon
  {
    @include bpgte(sm)
    {
      display: none;
    }
  }

  &-mobileLinks
  {
    position: fixed;
    background: white;
    z-index: 9;
    left: 0;
    right: 32px;
    top: 0;
    bottom: 0;
    transform: translateX(-101%);
    transition: all 0.3s ease-in;

    @include bpgte(sm)
    {
      display: none;
    }

    .isOpen &
    {
      transform: translateX(0);
    }
  }

  &-mobileTitleRow
  {
    padding: 1rem;
    background-color: $header-green;
    display: flex;
  }

  &-mobileTitle
  {
    color: white;
    text-transform: uppercase;
    margin-left: 10px;
  }

  &-mobileLinksList
  {
    padding: 1rem;

    a,
    a:link,
    a:visited
    {
      color: $brand-secondary;
    }
  }

  &-mobileLinkItem
  {
    height: 45px;
  }
}
