/** @define ResultList */

.ResultList
{
  &-item
  {
    border-bottom: $border-default;
    padding: 16px;

    @include bpgte(lg)
    {
      margin-left: -8px;
    }

    &:hover,
    &.is-hovered
    {
      @include bpgte(md)
      {
        background-color: #d8d8d8;
      }
    }

    &.is-selected
    {
      @include bpgte(md)
      {
        background-color: #d8d8d8;
      }
    }

    &--ordered
    {
      counter-increment: resultCount;
    }
  }
}
