//
// Base.scss
//
// Include the default css rules in this file. They should be single element selectors,
// with or without attributes, pseudo-classes, child or sibling selectors. What you are
// defining is the styles that should apply regardless of where the element is on the
// page.
@at-root
{
  html
  {
    font-size: 100%;
  }

  body
  {
    margin: 0;
  }

  .hide-overflow
  {
    overflow: hidden;
  }

  #main
  {
    @include font-base;
    top: 0;
  }

  #mobile-footer
  {
    margin-top: 0;
  }

  // pulled directly out of bootstrap so we can use them properly

  // Only display content to screen readers
  //
  // See: http://a11yproject.com/posts/how-to-hide-content/

  .sr-only
  {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  // Use in conjunction with .sr-only to only display content when it's focused.
  // Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
  // Credit: HTML5 Boilerplate

  .sr-only-focusable
  {
    &:active,
    &:focus
    {
      position: static;
      width: auto;
      height: auto;
      margin: 0;
      overflow: visible;
      clip: auto;
    }
  }
}

h1,
.h1
{
  @extend h1;

  @include font-h1;
}

h2,
.h2
{
  @extend h2;

  @include font-h2;
}

h3,
.h3
{
  @extend h3;

  @include font-h3;
}

h4,
.h4
{
  @extend h4;

  @include font-h4;
}

a
{
  color: $link-color-primary;

  &:active
  {
    color: $link-color-active;
  }
}

p
{
  @extend .paragraph-font;
}

img
{
  max-width: 100%;
}

strong
{
  font-weight: $font-weight-bold;
}

em
{
  // Please note, by default SSP Italic is not included.
}

footer,
.footer
{
  @include font-sizes(0.75, 1.5, 0.75, 1.5);
}

hr
{
  background-color: $gray-4;
  height: 1px;
}

//Client Override
table
{
  margin-bottom: 0;
}

//Client Override
table tr td,
table tr th
{
  font-size: inherit;
}

iframe
{
  border: none;
}
