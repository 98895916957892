/** @define Link */

@mixin Link--footer
{
  text-decoration: none;

  /* postcss-bem-linter: ignore */
  &:hover,
  &:active
  {
    text-decoration: underline;
  }

  /* postcss-bem-linter: ignore */
  &:visited
  {
    color: $footer-black;
  }
}

.Link
{
  text-decoration: none;

  &:hover
  {
    text-decoration: underline;
  }

  &--plain
  {
    color: inherit;

    &:link,
    &:hover,
    &:visited,
    &:active,
    &:focus
    {
      color: inherit;
    }
  }

  &--underlined
  {
    text-decoration: underline;
  }
}
