/** @define Locator */

$header-height-mobile: 50px !default;
$header-height-tablet: $header-height-mobile !default;
$header-height-desktop: 100px !default;
$results-width: 584px !default;

.Locator--ace
{
  position: relative;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: auto;
  height: 100%;
  font-family: $font-family-header_footer;


  overflow-y: hidden;

  .Locator
  {
    &-title,
    &-mobileTitle
    {
      @include font-sizes(20px, 28px);
      color: $locator-black;
      font-weight: $font-weight-bold;
      padding: 16px;
      padding-bottom: 0;
    }

    &-title
    {
      @include bplte(md)
      {
        display: none;
      }
    }

    &-mobileTitle
    {
      display: flex;
      justify-content: center;
      text-transform: none;

      @include bpgte(lg)
      {
        display: none;
      }
    }

    &-buttons
    {
      display: flex;
    }

    &-button
    {
      display: flex;
      justify-content: center;
      align-items: center;
      height: auto;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 8px;
      padding-right: 8px;

      &[disabled]
      {
        background-color: $gray-12;
        cursor: default;
      }

      &:not(:last-child)
      {
        margin-right: 1px;
      }

      &:not(:first-child)
      {
        margin-left: 1px;
      }

      /* postcss-bem-linter: ignore */
      .icon
      {
        width: 18px;
        height: 18px;
      }
    }

    &-inputWrapper
    {
      display: flex;
      flex-grow: 1;
    }

    &-input
    {
      margin-left: 16px;
      margin-top: 13px;
      margin-bottom: 15px;
      padding: 0;
      height: auto;
    }

    &-searchTitle
    {
      padding-left: 16px;
      padding-right: 16px;
    }

    &-results
    {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 0;
      overflow: auto;
    }

    &-searchWrapper
    {
      position: sticky;
      z-index: 1;
      padding: 16px;
      display: flex;
      flex-shrink: 0;
      width: 100%;
      top: 0;
      background-color: white;
    }

    &-overlay
    {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $locator-overlay-black;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.3s linear, opacity .3s linear;
      z-index: 2;

      &.is-active
      {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear, opacity .3s linear;
      }
    }

    &-map
    {
      display: none;
      position: sticky;
      top: 0;

      @include bpgte(md)
      {
        display: block;
      }
    }

    &-filterClearButton
    {
      text-decoration: underline;
      padding: 10px 0 10px 20px;

      &:hover
      {
        text-decoration: none;
      }
    }

    &-resultsWrapper
    {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      margin-left: 16px;
    }

    &-resultsSummary
    {
      @include font-sizes(16px, 20px);
      padding: 16px 0 4px;
      color: $gray-9;

      @include bpgte(lg)
      {
        border-bottom: $border-locator-new;
      }
    }

    &-content
    {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      width: 100%;
      height: 100%;
      position: relative;
      background-color: white;

      @include bpgte(md)
      {
        position: relative;
        bottom: 0;
        height: auto;
        width: $results-width;
      }
    }

    &-initial,
    &-noResults
    {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 80%;
    }

    /* postcss-bem-linter: ignore */
    &-filterFieldsWrapper
    {
      position: fixed;
      display: flex;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.3s, opacity .3s linear;
      z-index: 9999999;
      background-color: $white;

      @include bpgte(sm)
      {
        left: calc(100% - 219px);
        top: 50%;
        bottom: auto;
        right: auto;
        transform: translate(-50%, -50%);
        height: 100%;
        width: 439px;
      }

      /* postcss-bem-linter: ignore */
      svg
      {
        height: 16px;
        width: 16px;
      }
    }

    /* postcss-bem-linter: ignore */
    &-filters.is-active
    {
      /* postcss-bem-linter: ignore */
      .Locator-filterFieldsWrapper
      {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s, opacity .3s linear;
      }
    }

    &-filterFields
    {
      width: 100%;

      @include bpgte(sm)
      {
        width: 439px;
        height: 100%;
      }
    }

    &-filtersCount
    {
      margin-left: 3px;
    }

    &-form
    {
      display: flex;
      flex-direction: row;
      flex-grow: 1;
      flex-wrap: wrap;

      @include bplte(xs)
      {
        justify-content: center;
      }
    }

    &-formBordered
    {
      max-width: 309px;
      border: $border-default;
      border-radius: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include bplte(xs)
      {
        width: 247px;
      }

      @include bp(sm, sm)
      {
        width: 400px;
        max-width: none;
      }
    }

    &-geoButton
    {
      display: flex;
      align-items: center;
      margin-left: 15px;
    }
  }

  /* postcss-bem-linter: ignore */
  .c-map-with-pins-wrapper,
  .c-map-with-pins
  {
    height: 100%;
    width: 100%;
    top: 12px;

    @include bp(sm,md)
    {
      top: 40px;
    }
  }

  /* postcss-bem-linter: ignore */
  .search-button
  {
    background-color: inherit;
    padding: 0;
    margin-right: 15px;

    /* postcss-bem-linter: ignore */
    &:hover
    {
      background-color: inherit;
    }
  }

  /* postcss-bem-linter: ignore */
  .Locator-button .icon-geolocate_alt
  {
    width: 16px;
    height: 24px;
  }

  /* postcss-bem-linter: ignore */
  .js-filters-open
  {
    @include font-sizes(16px, 20px);
    font-family: $font-family-header_footer;
    color: $text-search;
    background-color: $gray-9;
    padding: 10px 34.5px;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: $filter-shadow;

    @include bp(sm, sm)
    {
      width: 100%;
    }

    @include bp(md, md)
    {
      margin-top: -45px;
    }
  }

  /* postcss-bem-linter: ignore */
  .ResultList
  {
    height: 600px;
    overflow-y: scroll;

    @include bplte(md)
    {
      margin-top: 16px;
    }

    @include bplte(xs)
    {
      margin-left: 15px;
      margin-right: 15px;
    }

    /* postcss-bem-linter: ignore */
    &-item
    {
      border-bottom: $border-locator-new;
      @include bplte(sm)
      {
        margin-left: -12px;
      }
    }

    /* postcss-bem-linter: ignore */
    &-item.is-selected
    {
      background-color: $gray-10;
    }

    /* postcss-bem-linter: ignore */
    &-item:first-child
    {
      border-top: $border-locator-new;
    }
  }
}

/* postcss-bem-linter: ignore */
@media only screen
and (min-device-width : 820px)
and (max-device-width : 1180px)
and (orientation : landscape)
{
  .Locator
  {
    justify-content: center;
  }
}
